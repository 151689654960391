import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import logo from './images/logo/Logo-lightblue-transparent.png';
import icon from './images/icons/icon-blue.png';
import Footer from './Footer.js';

function About() {
    const [showFooter, setShowFooter] = useState(true); // Initial state for footer visibility

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 1000);  // Simulate loading delay for fade-in effect
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="About">
      <header className="App-header">
        {/* Links for navigation */}
        <div className="topLinks"> {/* No need for additional class control */}
       <Link to="/about">About Us</Link>
       <Link to="/services">Services</Link> {/* Navigation links */}
    </div>

        {/* Logo */}
        <img src={icon} className={`App-logo-about fade-in ${showContent ? 'show' : ''}`} alt="logo" />

        {/* About page content */}
        <div className={`App-info fade-in ${showContent ? 'show' : ''}`}>
        
        <p>With over 20 years of experience in software development, DAILEY was founded as a technology services company, delivering custom solutions with a strong emphasis on exceptional customer service. Our expertise lies in leveraging technology to solve complex problems and provide tailored solutions that drive business growth.</p>

<p>At DAILEY, we believe that excellent customer service is the cornerstone of success. We approach every project with attention to detail, ensuring client satisfaction through our customer-first mindset, which is embedded in our company’s philosophy.</p>

<p>Our focus is on creating seamless, end-to-end technology solutions that address real-world challenges for individuals and businesses alike. From strategic planning to implementation, we ensure clients receive innovative, personalized solutions designed to enhance productivity and streamline operations.</p>

<p>By understanding the unique needs of each client, we’re committed to delivering an exceptional experience and the highest level of service in every engagement.</p>
        </div>
      </header>

      {/* Footer */}
      <Footer className={showFooter ? 'show' : ''} />
    </div>
  );
}

export default About;