import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'; // Import Router components

import './ServicesList.css'; // Import the CSS file for styles
import icon from './images/icons/icon-blue.png';
import Footer from './Footer';

const ServicesList = () => {
  const [showFooter, setShowFooter] = useState(true); // Initial state for footer visibility
  const [showContent, setShowContent] = useState(false);


  const services = [
    {
      title: 'Custom Software Development',
      description: 'Crafting tailored software solutions for unique business needs.',
    },
    {
      title: 'Web Hosting',
      description: 'Providing reliable and secure hosting solutions for websites.',
    },
    {
      title: 'Mobile Device Management (MDM)',
      description: 'Managing and securing mobile devices for businesses.',
    },
    {
      title: 'HR Onboarding Solutions',
      description: 'Streamlining employee onboarding processes with custom software made by DAILEY.',
    },
    {
      title: 'WordPress Development',
      description: 'Creating and maintaining custom WordPress websites.',
    },
    {
      title: 'API Integration',
      description: 'Integrating third-party APIs to enhance functionality for your new and existing tools.',
    },
    {
      title: 'Consulting Services',
      description: 'Providing expert advice on technology implementation and strategy.',
    },
    {
      title: 'HR Training and Workshops',
      description: 'Conducting workplace training, including harassment prevention and professional development.',
    },
    {
      title: 'Web App Development',
      description: 'Building responsive and interactive web applications.',
    },
    {
      title: 'HIPAA-Compliant Software',
      description: 'Developing healthcare-related software with a focus on data security.',
    },
  ];

  return (
    <>
    <img src={icon} className={`App-logo-about fade-in ${showContent ? 'show' : ''}`} alt="logo" />
    <div className="services-container">

    <div className={"topLinks"}>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link> {/* Navigation links */}
        </div>
      {services.map((service, index) => (
        <div
          key={index}
          className="service-card"
          style={{ animationDelay: `${index * 0.2}s` }} // Adding delay based on index
        >
          <h3 className="service-title">{service.title}</h3>
          <p className="service-description">{service.description}</p>
        </div>
      ))}
    </div>
    <Footer className={showFooter ? 'show' : ''}/>
    </>
  );
};

export default ServicesList;